
import { ThemeProvider } from '@mui/material/styles';
import ReactGA from 'react-ga4';

import customTheme from './theme';

import Home from './Home'


ReactGA.initialize('G-M7N2GBWP0E');


function App() {
  return (
    <div className="App">
      <ThemeProvider theme={customTheme}>
        <Home></Home>
      </ThemeProvider>
    </div>
  );
}

export default App;
