import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://469535d9343f49fa8d01a88efaf9acb2@o402599.ingest.sentry.io/6581216",
    integrations: [],
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
