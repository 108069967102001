import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Autocomplete from '@mui/material/Autocomplete';
import { IconButton } from '@mui/material';
import CloseFill from 'remixicon-react/CloseFillIcon'
import ArrowLeftRightLine from 'remixicon-react/ArrowLeftRightLineIcon'

import ReactGA from 'react-ga4';


import axios from 'axios';

let API_ENDPOINT;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  API_ENDPOINT = 'http://localhost:8000/api'
} else {
  API_ENDPOINT = 'https://api.tetun.org/api'
}


const languages = [
  {code: 'ind_Latn', label: 'Indonesia'},
  {code: 'ban_Latn', label: 'Bali'},
  {code: 'jav_Latn', label: 'Jawa'},
  {code: 'sun_Latn', label: 'Sunda'},
  {code: 'ace_Latn', label: 'Aceh'},
  {code: 'bjn_Latn', label: 'Banjar'},
  {code: 'bug_Latn', label: 'Bugis'},
  {code: 'min_Latn', label: 'Minangkabau'},
  {code: 'eng_Latn', label: 'Inggris'},
]

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Built by '}
      <Link color="inherit" href="https://twitter.com/RaphaelMerx" target="_blank" rel="noopener noreferrer">
        Raphael Merx
      </Link>{' using '}
      <Link color="inherit" href="https://arxiv.org/abs/2207.04672" target="_blank" rel="noopener noreferrer">
        NLLB by META AI
      </Link>
    </Typography>
  );
}

export default function Home() {

  const [text, setText] = useState(``);
  const [fixedText, setFixedText] = useState([]);
  const [loading, setLoading] = useState(false);
  const [src, setSrc] = useState({code: 'ind_Latn', label: 'Indonesia'});
  const [tgt, setTgt] = useState({code: 'ban_Latn', label: 'Bali'});
  const [dictionaries, setDictionaries] = useState({});

  React.useEffect(() => {
    // only import after component mounted
    import('./dictionary').then((module) => {
      setDictionaries(module.default);
    })
  }, [])

  const getDictionaryResult = (src, tgt, text) => {
    if (Object.keys(dictionaries).length === 0) return;
    if (src.code === 'eng_Latn' || tgt.code === 'eng_Latn') return;
    if (src.code === 'ind_Latn') {
      return dictionaries[tgt.code.slice(0, 3)].getTranslation(src.code, tgt.code, text)
    } else if (tgt.code === 'ind_Latn') {
      return dictionaries[src.code.slice(0, 3)].getTranslation(src.code, tgt.code, text)
    }
  }

  const translate = (event) => {
    event.preventDefault();
    if (!text) { return; }
    if (src.code === tgt.code) return;

    ReactGA.event({
      category: 'User',
      action: 'Request translation',
    });

    if (text.length < 20 && (src.code === 'ind_Latn' || tgt.code === 'ind_Latn')) {
      const dictionaryResult = getDictionaryResult(src, tgt, text)
      if (dictionaryResult) {
        setFixedText([dictionaryResult])
        return;
      }
    }

    setLoading(true)
    const params = {
      src: src.code,
      tgt: tgt.code,
      q: text
    }

    axios.get(API_ENDPOINT, { params })
      .then(response => {
        const result = response.data.result
        setFixedText(result.split('<p>'))
        setLoading(false)
      }).catch((error) => {
        setLoading(false)
        // eslint-disable-next-line no-alert
        window.alert(error);
      });
  };

  const swapLanguages = () => {
    setSrc(tgt)
    setTgt(src)
  }

  const setSrcHandler = (event, value) => {
    setSrc(value)
    if (value.code === tgt.code) {
      // set tgt to old src
      setTgt(src)
    }
  }

  const setTgtHandler = (event, value) => {
    setTgt(value)
    if (value.code === src.code) {
      // set src to old tgt
      setSrc(tgt)
    }
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 10 }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 5,
          marginBottom: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Penerjemah bahasa daerah Indonesia
        </Typography>
      </Box>
      <Box id="language-select-row">
        <Autocomplete
          disablePortal
          disableClearable
          value={src}
          id="lang-src"
          size="small"
          options={languages}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          sx={{ width: 200 }}
          renderInput={(params) => <TextField {...params} label="Dari bahasa" />}
          onChange={setSrcHandler}
        />
        <IconButton onClick={swapLanguages} size="small">
          <ArrowLeftRightLine className="language-swap-icon"></ArrowLeftRightLine>
        </IconButton>
        <Autocomplete
          disablePortal
          disableClearable
          value={tgt}
          id="lang-tgt"
          size="small"
          options={languages}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          sx={{ width: 200 }}
          renderInput={(params) => <TextField {...params} label="Ke bahasa" />}
          onChange={setTgtHandler}
        />
      </Box>
      <Box>
        <Box sx={{ mt: 1 }}>
          <Box className="text-input-container" style={{position: 'relative'}}>
            {text && <IconButton onClick={() => setText('')} className="text-clear" size="small">
              <CloseFill></CloseFill>
            </IconButton>}
            <TextField
              margin="normal"
              required
              fullWidth
              id="ban-text"
              label="Masukkan teks di sini"
              name="ban-text"
              minRows="3"
              onChange={(e) => setText(e.target.value)}
              spellCheck={false}
              value={text}
              multiline
              autoFocus
            />
          </Box>
          <LoadingButton
            onClick={translate}
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 3 }}
            loading={loading}
            disabled={!text}
          >
            Menerjemahkan
          </LoadingButton>
          <Box container>
            {fixedText.map((text, index) => <p>{text}</p>)}
          </Box>
        </Box>
      </Box>
      <Copyright sx={{
          mt: 12,
          mb: 4,
          position: 'fixed',
          bottom: 0,
        }} />
    </Container>
  );
}
